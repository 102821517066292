import React, { Component } from "react";
import moment from "moment";
import config from "../src/configurations/config";
import { AES, enc } from "crypto-js";

const close = require("../src/assets/images/close_black.svg");
const checked = require("../src/assets/images/checked.png");

export class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      termsAcceptedDate: "",
      isOpen: false,
      memberId: "",
      agentId: "",
      channelId: "web",
    };
  }

  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    console.log(params);
    const encryptedData = params.get("data");
    let dataString = "";
    let data = {};
    let memberId = "";
    let agentId = "";

    let key = enc.Utf8.parse("000102030405060708090a0b0c0d0e0f");
    let iv = enc.Utf8.parse("4t7w9z$C&F)J@NcR");

    if (
      encryptedData !== null &&
      encryptedData !== undefined &&
      encryptedData !== ""
    ) {
      dataString = AES.decrypt(encryptedData, key, { iv }).toString(enc.Utf8);
      data = JSON.parse(dataString);
    }
    const channelUId = data.ChannelUId || "web";
    this.setState({ channelId: channelUId });

    if (data.memberId !== null && data.memberId !== undefined) {
      memberId = data.memberId;
    }
    if (data.agentId !== null && data.agentId !== undefined) {
      agentId = data.agentId;
    }
    if (memberId !== "") {
      this.setState({ memberId: memberId });
    }
    if (agentId !== "") {
      this.setState({ agentId: agentId });
    }
    if (agentId !== null && agentId !== undefined && agentId !== "") {
      this.checkTerms(agentId);
    } else if (memberId !== null && memberId !== undefined && memberId !== "") {
      this.checkTerms(memberId);
    }
  }

  onClickTerms = () => {
    this.setState({ isOpen: true });
  };

  cancelTerms = () => {
    this.setState({ isOpen: false });
  };

  checkTerms = (id) => {
    fetch(config.hostURLChatBot + "tcget/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let data = responseJson;
        if (data) {
          if (data.has_accepted) {
            this.setState({
              termsAcceptedDate: data.date,
              isOpen: false,
            });
            this.props.handleAccept();
          } else {
            this.setState({
              isOpen: true,
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  acceptTerms = () => {
    let id = "";
    if (
      this.state.agentId !== undefined &&
      this.state.agentId !== null &&
      this.state.agentId !== ""
    ) {
      id = this.state.agentId;
    } else if (
      this.state.memberId !== undefined &&
      this.state.memberId !== null &&
      this.state.memberId !== ""
    ) {
      id = this.state.memberId;
    }
    const dt = new Date();
    const body = {
      uid: id,
      channel: this.state.channelId,
      has_accepted: true,
      // date: moment(dt).format("MM-DD-YYYY"),
      // time: "",
    };
    const bodyToSend = JSON.stringify(body);
    fetch(config.hostURLChatBot + "tcadd", {
      method: "POST",
      body: bodyToSend,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let data = responseJson;
        if (data) {
          this.setState({
            isOpen: false,
            termsAcceptedDate: moment(dt).format("MM-DD-YYYY"),
          });
          this.props.handleAccept();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    return (
      <>
        {/* <div className="footer-container">
          <div
            className="terms-content"
          >
            <div className="terms-btn" onClick={() => this.onClickTerms()}>
              {this.state.termsAcceptedDate === "" ? (
                <div className="check-box"></div>
              ) : (
                <div className="check-box checked">
                  <img src={checked.default} alt="" />
                </div>
              )}
              <div>Terms of Use</div>
            </div>
            {this.state.termsAcceptedDate && (
              <div className="terms-info accepted">
                Read & accepted on {this.state.termsAcceptedDate}
              </div>
            )}
          </div>
        </div> */}

        <div
          className={
            this.state.isOpen ? "terms-of-use-modal open" : "terms-of-use-modal"
          }
        >

          <div style={{ padding: 20 }} className="modal-box">
            <div className="modal-header">
              <div>Terms of Use</div>

              {/* <div className="modal-close" onClick={() => this.cancelTerms()}>
                <img src={close.default} alt="" />
              </div> */}
            </div>

            <div className="modal-body">
              The Ask Caryn tool and resulting search results are built on Artificial Intelligence algorithms, to search our cache of documents based on your expressed intent. The top search result may not be the answer you seek/need. If in doubt, please refer the Plan Guidelines or contact Customer Service.
            </div>

            <div className="modal-footer">
              {this.state.termsAcceptedDate ? (
                <div className="terms-info">
                  Read & accepted on {this.state.termsAcceptedDate}
                </div>
              ) : (
                <div style={{ padding: 20 }}>
                  {/* <button
                    onClick={() => this.cancelTerms()}
                    data-dismiss="modal"
                  >
                    CANCEL
                  </button> */}
                  <button
                    // onClick={() => this.acceptTerms()}
                    data-dismiss="modal"
                  >
                    ACCEPT
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Footer;
