import React, { Component } from "react";
import config from "../src/configurations/config";
import {
  faThumbsUp,
  faThumbsDown,
  faClose,
  faScrewdriverWrench,
  faChevronUp,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Modal, Button } from 'react-bootstrap';
import decryptValue from "./actions/decrypt";

const search = require("../src/assets/images/ask_caryn_icon.svg");
const close = require("../src/assets/images/close.svg");
const upArrow = require("../src/assets/images/up-arrow.png");


const SearchComponent = (props) => (
  <div style={{ height: 70, display: "flex", alignItems: "center", backgroundColor: "#cdaccf" }}>
    <div style={{ width: "100%" }}>

      <div style={{ padding: 10, display: "flex" }}>

        <input
          style={{ width: "100%", height: 38, border: "2px solid #533278" }}
          type="text"
          placeholder="Do you have a question?"
          onChange={props.onSearchInput}
          value={props.query}
          onKeyPress={props.onKeyPress}
        />
        {props.showIcon && (
          <div style={{ backgroundColor: "#533278", height: 38 }}>
            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img style={{ width: "100%" }} src={props.searchIcon} alt="" onClick={props.searchClick} />
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
);

export class Main extends Component {
  constructor(props) {
    super(props);
    this.optionsRef = React.createRef();

    this.state = {
      answerI: 0,
      additionalTextI: 0,
      answerSpeed: 10,
      llmQuestionI: 0,
      uid: "",
      qid: "",
      query: "",
      answerList: [],
      answer: "",
      noAnswerToDisplay: false,
      answerLoader: "",
      valueAns: 10,
      valueDoc: 10,
      isDismissed: false,
      isDismissedSummary: false,
      isTextDisabled: false,
      dropSelected: "",
      comparitatorDropSelected: "",
      isNav: true,
      isMobile: false,
      isToolTip: false,
      channelId: "web",
      memberId: "",
      agentId: "",
      contextKeywords: [],
      complete_contexts_summary: "",
      thumpsup_selected_index: 0,
      thumpsdown_selected_index: 0,
      show_full_answer: "",
      show_full_answer_index: "",
      showFeedBackButton: false,
      showFeedbackMessage: false,
      dropList: [],
      comparitatorDropList: [],
      sourceFile: "",
      showComparitorToolModal: false,
      multipdropdownList: [],
      comparitorAnswer: "",
      comparitorAnswerLoading: false,
      modalQuery: "",
      showDropdown: false,
      isSubDropdownOpen: false,
      comparitorResponseType: "general",
      comparitorTableResponse: [],
      userPlanName: "",
      userAge: "",
      userProgramName: "",
      agumentedQuestion: "",
      showAugmentedQuestion: false,
      errorMsg: "",
      userGender: "",
      programBeginDate: "",
      programEndDate: "",
      terminateUserNotes: "",
      terminateUserReason: "",
      addons: "",
      paidThrudate: "",
      channels: "",
      NumberOfMembersInHousehold: "",
      application: "",
      dateOfSale: "",
      memberStatus: "",
      enrollmentStatus: "",
      healthInfoStatus: "",
      nextRecurringPaymentDate: "",
      draftNDaysBefore: "",
      primarySubscriberName: "",
      LLM_generated_questions: ""
    }
  }


  fetchDropDownHandler() {
    const jsonUrl = config.jsonUrl;

    fetch(jsonUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((jsonContent) => {
        console.log('JSON data from S3:', jsonContent);
        console.log('Selected dropdown:', jsonContent[0].planName);
        console.log('first dropdown array', jsonContent[0]);
        this.setState({
          dropList: jsonContent,
          dropSelected: jsonContent[0].planName,
          contextKeywords: [jsonContent[0]]
        });

        // You can use `jsonContent` in your React application
      })
      .catch((error) => {
        console.error('Error reading JSON file:', error);
      });

  }

  fetchComparitorDropDownHandler() {

    const jsonUrl = config.jsonUrlComparitator;

    fetch(jsonUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((jsonContent) => {
        this.setState({
          comparitatorDropList: jsonContent,
          comparitatorDropSelected: jsonContent[0].planName,
          contextKeywords: [jsonContent[0]]
        })
        // You can use `jsonContent` in your React application
      })
      .catch((error) => {
        console.error('Error reading JSON file:', error);
      });

  }

  showComparitorTool() {
    this.setState({
      showComparitorToolModal: true,
      multipdropdownList: [],
      comparitorAnswer: "",
      comparitorAnswerLoading: false
    })
  }

  hideComparitorTool() {
    this.setState({
      showComparitorToolModal: false,
      modalQuery: ""
    })
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidMount() {
    this.fetchDropDownHandler();
    this.fetchComparitorDropDownHandler()

    document.addEventListener(
      "message",
      (event) => {
        this.setState({ query: event.data });
        setTimeout(() => {
          this.search();
        }, 3000);
      },
      false
    );

    document.addEventListener('mousedown', this.handleClickOutside);

    const search = window.location.search;

    const params = new URLSearchParams(search);

    const isNav = params.get("isnav");
    if (isNav === "false") {
      this.setState({ isNav: false });
    }
    const isMobile = params.get("ismobile");
    if (isMobile === "true") {
      this.setState({ isMobile: true });
    }

    const encryptedData = params.get("result");

    let data = {};

    const decryptionValues = decryptValue(encryptedData);
    let contextKeywords = [];
    if (data.contextkeywords !== null && data.contextkeywords !== undefined) {
      //contextKeywords = data.contextkeywords;
      this.setState({ dropSelected: data.contextkeywords[0].programName });
      contextKeywords = [
        {
          programName: data.contextkeywords[0].programName,
          planName: "",
        },
      ];
    } else {
      contextKeywords = [
        {
          programName: this.state.dropSelected,
          planName: "",
        },
      ];
    }

    const query = data.query || "";

    if ((query !== null) & (query !== undefined)) {
      this.setState({ query: query });
      setTimeout(() => {
        this.search();
      }, 1000);
    }

    // console.log(decryptionValues, "---------> Decrypted Values.");

    this.setState({
      contextKeywords: contextKeywords,
      channelId: decryptionValues?.ChannelUId,
      memberId: decryptionValues?.memberId,
      agentId: decryptionValues?.agentId,
      userPlanName: decryptionValues?.planname,
      userAge: decryptionValues?.memberage,
      userProgramName: decryptionValues?.programname,
      userGender: decryptionValues?.gender,
      programBeginDate: decryptionValues?.programeBeginDate,
      programEndDate: decryptionValues?.programeEndDate,
      terminateUserNotes: decryptionValues?.terminateUserNotes,
      terminateUserReason: decryptionValues?.terminateUserReason,
      addons: decryptionValues?.addons,
      paidThrudate: decryptionValues?.paidThrudate,
      channels: decryptionValues?.Channels,
      NumberOfMembersInHousehold: decryptionValues?.NumberOfMembersInHousehold,
      application: decryptionValues?.Application,
      dateOfSale: decryptionValues?.DateofSale,
      memberStatus: decryptionValues?.Status,
      enrollmentStatus: decryptionValues?.EnrollmentStatus,
      healthInfoStatus: decryptionValues?.HealthInfoStatus,
      nextRecurringPaymentDate: decryptionValues?.NextRecurringPaymentDate,
      draftNDaysBefore: decryptionValues?.DraftNDaysBefore,
      primarySubscriberName: decryptionValues?.PrimarySubscriberName
    });
  }

  dropSelect = (e) => {
    e.preventDefault();
    this.setState({
      dropSelected: e.target.value,
      contextKeywords: [
        {
          programName: e.target.value,
          planName: "",
        },
      ],
    });
  };

  onEnableTextSearch() {
    this.setState({ isTextDisabled: false });
  }

  onSearchInput = (e) => {
    this.setState({
      query: e.target.value,
    });
  };

  onModalSearchInput = (e) => {
    this.setState({
      modalQuery: e.target.value,
    });
  };


  onEnterPress = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.search();

    }
  };

  showTooltip = () => {
    this.setState({
      isToolTip: true,
    });
    setTimeout(() => {
      this.setState({
        isToolTip: false,
      });
    }, 2000);
  };

  onDismiss = () => {
    this.setState({ isDismissed: true });
  };

  onDismissSummary = () => {
    this.setState({ isDismissedSummary: true });
  }

  onClickOpenFile = (value) => {

    localStorage.setItem("pdfUrl", value.url);
    localStorage.setItem("searchTxt", this.getWords(value.context));
    // localStorage.setItem("searchTxt", `\n \n \n \n \n \n \n \n \nUHFR100323E100323 \n \nPage 113 \n \nvii. \nAnnual Visits & Hospitalization Sharing Limits \n \nSharing is limited`);

    localStorage.setItem("pageNum", (parseInt(value.page_no) + 1));
    localStorage.setItem("pdfName", this.state.sourceFile);

    if (this.state.isMobile) {
      let url =
        `${config.pdfUrlRedirect}/pdf-viewer?pdfUrl=` +
        encodeURIComponent(value.meta.url) +
        "&searchTxt=" +
        encodeURIComponent(this.getWords(value.context)) +
        "&pageNum=" +
        value.pageno +
        "&pdfName=" +
        value?.meta?.name;
      window.open(url, "pdfViewer");
    } else {

      let url =
        `${config.pdfUrlRedirect}/pdf-viewer?pdfUrl=` +
        encodeURIComponent(value.url) +
        "&searchTxt=" +
        encodeURIComponent(this.getWords(value.context)) +
        "&pageNum=" +
        (parseInt(value.page_no) + 1) +
        "&pdfName=" +
        this.state.sourceFile;
      window.open(url, "pdfViewer");
    }

  };


  getWords = (str) => {
    return str.split(/\s+/).slice(0, 10).join(" ");
  };

  search = () => {
    if (this.state.query.trim().length > 0) {
      this.setState({
        answerLoader: 1,
        noAnswerToDisplay: false,
        answerList: [],
        answer: "",
        thumpsdown_selected_index: 0,
        thumpsup_selected_index: 0,
        showFeedbackMessage: false,
        showAugmentedQuestion: false,
        answerI: 0,
        additionalTextI: 0,
        llmQuestionI: 0
      });

      const body = {
        "data": {
          "query": this.state.query
        },
        "userdata": {
          "MemberID": this.state.memberId,
          "Application": this.state.application,
          "UserAge": this.state.userAge,
          "UserPlanName": this.state.userPlanName,
          "UserProgramName": this.state.userProgramName,
          "NOFmembersPerHousehold": this.state.NumberOfMembersInHousehold,
          "BenefitTier": "string",
          "PrimarySubscriberName": this.state.primarySubscriberName,
          "UserGender": this.state.userGender,
          "ProgramBeginDate": this.state.programBeginDate,
          "ProgramEndDate": this.state.programEndDate,
          "TerminateUserNotes": this.state.terminateUserNotes,
          "TerminateUserReason": this.state.terminateUserReason,
          "AddonsName": this.state.addons,
          "AgentEmail": "string",
          "PaidThruDate": this.state.paidThrudate,
          "DataOfsale": this.state.dateOfSale,
          "Channels": this.state.channels,
          "MemberStatus": this.state.memberStatus,
          "EnrollmentStatus": this.state.enrollmentStatus,
          "HealthQuestionnairesStatus": this.state.healthInfoStatus,
          "DraftNDaysBefore": this.state.draftNDaysBefore,
          "NextRecurringPaymentDate": this.state.nextRecurringPaymentDate
        }
      }

      const bodyToSend = JSON.stringify(body);

      fetch(config.hostDocGen + "GPT_answer_generation_with_agumentation", {
        method: "POST",
        body: bodyToSend,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          let data = responseJson?.data[0];

          if (data) {
            if (responseJson?.status == "FAILED") {
              this.setState({
                errorMsg: responseJson?.message
              })
            }
            this.setState({
              answer: data.answer,
              LLM_generated_questions: data?.LLM_generated_questions,
              answerList: data.complete_source_contexts,
              sourceFile: data.source_file,
              qid: data.qid,
              noAnswerToDisplay: false,
              answerLoader: "",
              isDismissed: false,
              showFeedBackButton: false,
              agumentedQuestion: data?.agumented_question,
              showAugmentedQuestion: true
            });
            this.typeWriter();
            this.runLoopAnswer(0);
          } else {
            this.setState({
              answerList: [],
              uid: "",
              noAnswerToDisplay: true,
              answerLoader: "",
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };


  augmentSearchHandler = () => {

    this.setState({
      answerLoader: 1,
      noAnswerToDisplay: false,
      answerList: [],
      answer: "",
      thumpsdown_selected_index: 0,
      thumpsup_selected_index: 0,
      showFeedbackMessage: false,
      showAugmentedQuestion: true,
      answerI: 0
    });

    const body = {
      "data": {
        "query": this.state.agumentedQuestion
      },
      "userdata": {
        "UserProgramName": this.state.userProgramName || "healthy life community"
      }
    }

    const bodyToSend = JSON.stringify(body);
    fetch(config.hostDocGen + "GPT_answer_generation_without_agumentation", {
      method: "POST",
      body: bodyToSend,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {

        let data = responseJson?.data[0];

        if (data) {
          if (responseJson?.status == "FAILED") {
            this.setState({
              errorMsg: responseJson?.message
            })
          } else {
            this.setState({
              answer: data.answer,
              answerList: data.complete_source_contexts,
              sourceFile: data.source_file,
              qid: data.qid,
              noAnswerToDisplay: false,
              answerLoader: "",
              isDismissed: false,
              showFeedBackButton: false,
            });
            this.typeWriter();
            this.runLoopAnswer(0)
          }

        } else {
          this.setState({
            answerList: [],
            uid: "",
            noAnswerToDisplay: true,
            answerLoader: "",
          });
        }
      }).catch((err) => {
        console.log(err);
      })
  }

  seeMoreHandler = (index) => {
    this.setState({
      show_full_answer: true,
      show_full_answer_index: index
    })
  }

  seeLessHandler = (index) => {
    this.setState({
      show_full_answer: false,
      show_full_answer_index: index
    })
  }

  typeWriter = () => {
    const { answerI, answerSpeed, answer } = this.state;

    if (answerI < answer.length) {

      this.setState((prevState) => ({
        answerI: prevState.answerI + 1,
      }), () => {
        if (document.getElementById("answer") != null) {

          const char = answer.charAt(answerI);

          if (char === '\n') {
            document.getElementById("answer").innerHTML += '<br />';
          } else {
            document.getElementById("answer").innerHTML += char;
          }
        }
      });

      setTimeout(this.typeWriter, answerSpeed);

      if (answerI == answer.length - 1) {
        this.additionalTextWriter();
      }
    }
  }

  additionalTextWriter = () => {
    const { additionalTextI, answerSpeed, LLM_generated_questions } = this.state;

    if (additionalTextI < LLM_generated_questions?.additional_text.length) {
      this.setState((prevState) => ({
        additionalTextI: prevState.additionalTextI + 1,
      }), () => {
        if (document.getElementById("question") != null) {

          const char = LLM_generated_questions?.additional_text.charAt(additionalTextI);

          if (char === '\n') {
            document.getElementById("question").innerHTML += '<br />';
          } else {
            document.getElementById("question").innerHTML += char;
          }
        }
      });

      setTimeout(this.additionalTextWriter, answerSpeed);
      if (additionalTextI == LLM_generated_questions?.additional_text.length - 1) {
        this.questionWriter();
      }
    }
  }


  questionWriter = () => {

    const { llmQuestionI, answerSpeed, LLM_generated_questions } = this.state;


    let index = 0;

    const typeQuestion = () => {
      if (index < LLM_generated_questions?.LLM_generated_questions.length) {
        const question = LLM_generated_questions?.LLM_generated_questions[index].question;
        const questionElement = document.getElementById("question");

        const writeChar = (llmQuestionI) => {
          if (llmQuestionI < question.length) {
            questionElement.innerHTML += question.charAt(llmQuestionI);
            setTimeout(() => writeChar(llmQuestionI + 1), answerSpeed); // Adjust the delay here (50ms for example)
          } else {
            questionElement.innerHTML += "<br />";
            index++;
            setTimeout(typeQuestion, 1000); // Adjust the delay between questions here (1000ms for example)
          }
        };

        writeChar(llmQuestionI);
      }
    };

    typeQuestion();
  }




  comparitorTypeWriter = () => {
    const { i, answerSpeed, comparitorAnswer } = this.state;

    if (i < comparitorAnswer.length) {
      this.setState((prevState) => ({
        i: prevState.i + 1,
      }), () => {
        if (document.getElementById("comparitoranswer") != null) {
          const char = comparitorAnswer.charAt(i);
          if (char === '\n') {
            document.getElementById("comparitoranswer").innerHTML += '<br />';
          } else {
            document.getElementById("comparitoranswer").innerHTML += char;
          }
        }
      });

      setTimeout(this.comparitorTypeWriter, answerSpeed);
    }
  }


  feedbackThumpsDownHandler = () => {
    this.setState({
      thumpsup_selected_index: 0,
      thumpsdown_selected_index: 2,
    })

    setTimeout(() => {
      this.setState({
        showFeedBackButton: false,
        showFeedbackMessage: true
      })
    }, [1000]);


    const loggingBody = {
      qid: this.state.qid,
      query: this.state.query,
      programName: this.state.userProgramName,
      memID: this.state.memberId ? this.state.memberId : null,
      agentID: this.state.agentId ? this.state.agentId : null,
      channelID: this.state.application ? this.state.application : null,
      answerselected: this.state.answer,
      agumentedQuestion: this.state.agumentedQuestion
    };

    const loggingBodyToSend = JSON.stringify(loggingBody);

    fetch(config.hostDocGen + "thumbsdown_feedback", {
      method: "POST",
      body: loggingBodyToSend,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
      });
  }


  feedbackThumpsUpHandler = () => {
    this.setState({
      thumpsup_selected_index: 1,
      thumpsdown_selected_index: 0,
    })

    setTimeout(() => {
      this.setState({
        showFeedBackButton: false,
        showFeedbackMessage: true
      })
    }, [1000]);

    const loggingBody = {
      qid: this.state.qid,
      query: this.state.query,
      programName: this.state.userProgramName,
      memID: this.state.memberId ? this.state.memberId : null,
      agentID: this.state.agentId ? this.state.agentId : null,
      channelID: this.state.application ? this.state.application : null,
      answerselected: this.state.answer,
      agumentedQuestion: this.state.agumentedQuestion
    };

    const loggingBodyToSend = JSON.stringify(loggingBody);

    fetch(config.hostDocGen + "thumbsup_feedback", {
      method: "POST",
      body: loggingBodyToSend,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
      });
  }

  runLoopAnswer(answerI) {
    const { answer } = this.state;
    // const answerSpeed = 50;

    if (answerI < answer?.length) {

      if (answerI === answer?.length - 1) {
        this.setState({
          showFeedBackButton: true
        })
      }
      // Increment i and call runLoop again after a delay
      setTimeout(() => {
        this.runLoopAnswer(answerI + 1);
      }, this.state.answerSpeed);
    }
  }


  addMoreDropdownHandler = () => {
    this.setState({
      showDropdown: true
    });
  }

  handleDropdownToggle = () => {
    this.setState({
      isSubDropdownOpen: !this.state.isSubDropdownOpen
    })
  };


  optionSelectedHandler = (item) => {
    // Check if the item already exists in multipdropdownList
    if (!this.state.multipdropdownList.some(existingItem => existingItem === item.programName)) {
      // If the item doesn't exist, create a new array with the item added. 
      const newArray = [...this.state.multipdropdownList, item.programName];
      // Update the state with the new array
      this.setState({ multipdropdownList: newArray });
    }
    // If the item already exists, you may choose to handle it differently or do nothing
  }



  modalSearchHandler = () => {

    this.setState({
      comparitorAnswerLoading: true,
      showDropdown: false,
      comparitorAnswer: "",
      answerI: 0
    })

    const body = {
      "documents": {
        "document_list": this.state.multipdropdownList
      },
      "question": {
        "question": this.state.modalQuery
      }
    }

    fetch(config.hostDocGen + "compare_documents", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {

        console.log("Comparitator Tool Results :------------->", responseJson);
        console.log("Comparotator Type:-------->", responseJson[0]?.type);

        const data = responseJson?.output;

        if (responseJson[0]?.type == "table") {
          this.setState({
            comparitorResponseType: responseJson[0]?.type,
            comparitorAnswerLoading: false,
            comparitorTableResponse: responseJson[0]?.data
          });

        } else {
          this.setState({
            comparitorResponseType: responseJson[0]?.type,
            comparitorAnswer: responseJson?.output,
            comparitorAnswerLoading: false
          });
        }
        this.comparitorTypeWriter();
      })
      .catch((err) => {
        console.log(err);
      })
  }


  optionsCloseHandler = (index) => {
    const newArray = [...this.state.multipdropdownList];
    newArray.splice(index, 1);
    // Update the state with the new array
    this.setState({ multipdropdownList: newArray });
  }


  handleClickOutside = (event) => {
    if (this.optionsRef.current && !this.optionsRef.current.contains(event.target)) {
      // Clicked outside the component, trigger your function here
      this.handleOutsideClick();
    }
  };

  handleOutsideClick = () => {
    this.setState({
      showDropdown: false
    })
  };


  handleAugmentInputChange = (event) => {
    this.setState({ agumentedQuestion: event.target.value });
  };


  render() {

    return (
      <div className="main-container">
        {this.state.showComparitorToolModal && (
          <Modal
            show={this.state.showComparitorToolModal}
            onHide={() => this.hideComparitorTool(false)}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <FontAwesomeIcon
                  style={{ cursor: "pointer", paddingRight: 10 }}
                  icon={faScrewdriverWrench}
                  size="1x"
                  className="fa-solid"
                />
                Comparator Tool
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="searchComponnet">
                <SearchComponent
                  dropSelect={this.dropSelect}
                  dropSelected={this.state.comparitatorDropSelected}
                  dropList={this.state.comparitatorDropList}
                  onSearchInput={this.onModalSearchInput}
                  query={this.state.modalQuery}
                  onKeyPress={null}
                  searchIcon={search.default}
                  searchClick={this.search}
                  showIcon={false}
                  showDropdown={false}
                  modalSearch={true}
                />
              </div>

              <div className="ComparitatorComponent">
                <div className="comparitorLeftSide">
                  {this.state.multipdropdownList.map((item, index) => (
                    <div key={index} className="multiselection">
                      <span>{item}</span>
                      <span className="modalOptionsCloseIcon">
                        <FontAwesomeIcon
                          onClick={() => this.optionsCloseHandler(index)}
                          style={{ cursor: "pointer" }}
                          icon={faClose}
                          size="1x"
                        />
                      </span>
                    </div>
                  ))}
                </div>
                <div className="comparitorRightSide">
                  <div onClick={() => this.addMoreDropdownHandler()} className="addMoreButton">
                    + Add Programs
                  </div>

                  {this.state.showDropdown && (
                    <div ref={this.optionsRef} className="addMoreSelectionDropdown">
                      {this.state.comparitatorDropList.map((item, index) => (
                        <>
                          <div className="dropdownOptions" key={index}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="18"
                              width="14"
                              viewBox="0 0 384 512"
                            ><path d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" /></svg>

                            {item.subPrograms.length > 0 ? (

                              <span style={{ padding: 10 }}>
                                <span onClick={() => this.optionSelectedHandler(item)}> {item.programName}</span>

                                {this.state.isSubDropdownOpen ? (
                                  <FontAwesomeIcon
                                    onClick={() => this.handleDropdownToggle()}
                                    style={{ cursor: "pointer", marginLeft: 10 }}
                                    icon={faChevronUp}
                                    size="1x"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    onClick={() => this.handleDropdownToggle()}
                                    style={{ cursor: "pointer", marginLeft: 10 }}
                                    icon={faChevronDown}
                                    size="1x"
                                  />
                                )}
                              </span>

                            ) : (
                              <span onClick={() => this.optionSelectedHandler(item)} style={{ padding: 10 }}>
                                {item.programName}
                              </span>
                            )}
                          </div>

                          {this.state.isSubDropdownOpen && item.subPrograms.length > 0 && (
                            <>
                              {item.subPrograms.map((item, index) => (
                                <div onClick={() => this.optionSelectedHandler(item)} className="dropdownSubOptions" key={index}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="18"
                                    width="14"
                                    viewBox="0 0 384 512"
                                  ><path d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" /></svg>

                                  <span style={{ padding: 10, fontSize: 12, fontWeight: "bold" }}>
                                    {item.programName}
                                  </span>
                                </div>
                              ))}
                            </>
                          )}
                        </>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              {this.state.multipdropdownList.length >= 1 && (
                <div className="modal-search-container">
                  <div onClick={() => this.modalSearchHandler()} className="search-button">
                    Compare
                  </div>
                </div>
              )}

              <>
                {this.state.comparitorAnswerLoading ? (
                  <div className="modal-loader-div">
                    <div>
                      <div className="loader" />
                      <div className="loader-msg">Generating Results..</div>
                    </div>
                  </div>
                ) : (
                  <>
                    {this.state.comparitorAnswer && (
                      <div className="modal-results-container">
                        <div className="comparitorTitle" >Comparison Answer:</div>
                        <br />
                        <p id="comparitoranswer"></p>
                      </div>
                    )}

                    {this.state.comparitorResponseType == "table" && (
                      <div className="modal-results-container">
                        <div className="comparitorTitle" >Comparison Answer:</div>
                        <br />

                        <div>
                          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                            <thead>
                              <tr>
                                <th style={{ border: '1px solid #ddd', padding: '8px' }}>No</th>
                                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Program Name</th>
                                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Program Information</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.comparitorTableResponse?.map((item, index) => (
                                <tr>
                                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{index + 1}</td>
                                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item?.Program_name}</td>
                                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item?.Program_information}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                      </div>
                    )}
                  </>
                )}
              </>

            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => this.hideComparitorTool()}
              >Close</Button>
            </Modal.Footer>
          </Modal>
        )
        }


        {
          this.state.isTextDisabled ? (
            <div className="disabled-input-section" onClick={this.showTooltip}>
              {this.state.isToolTip ? (
                <div className="tooltiptext">Please acknowledge terms of use</div>
              ) : null}
              <input
                className="main-input"
                type="text"
                placeholder="Do you have a question?"
                value={this.state.query}
                disabled
              />
              <div className="search-img">
                <img src={search.default} alt="" />
              </div>
              <select
                // onChange={channelSelect}
                style={{ textAlign: "center" }}
                className="dropDown-img"
              >
                <option value="select"> Select </option>
              </select>
            </div>
          ) : (


            <SearchComponent
              dropSelect={this.dropSelect}
              dropSelected={this.state.dropSelected}
              dropList={this.state.dropList}
              onSearchInput={this.onSearchInput}
              query={this.state.query}
              onKeyPress={this.onEnterPress}
              searchIcon={search.default}
              searchClick={this.search}
              showIcon={true}
              showDropdown={true}
              modalSearch={false}
            />

          )
        }


        <div className={this.state.isNav ? "main-body" : "main-body no-nav"}>
          {!this.state.isDismissed ? (
            <div className="dismissable-info">
              <div className="dismiss">
                <img
                  className="close"
                  src={close.default}
                  alt="X"
                  onClick={this.onDismiss}
                />
              </div>
              The Ask Caryn tool and resulting search results are built on Artificial Intelligence algorithms, to search our cache of documents based on your expressed intent. The top search result may not be the answer you seek/need. If in doubt, please refer the Plan Guidelines or contact Customer Service.
            </div>
          ) : null}


          {this.state.errorMsg != "" && (
            <div style={{ marginTop: 8, backgroundColor: "white", border: "2px solid gray", color: "black" }} className="dismissable-info">
              <div style={{ fontWeight: "bold", textDecoration: "underline", fontSize: 25 }}>Error :</div>

              <div className="dismiss">
                <img
                  className="close"
                  src={close.default}
                  alt="X"
                  onClick={this.onDismiss}
                />
              </div>
              <div style={{ marginTop: 7, fontSize: 20 }}>{this.state.errorMsg}</div>
            </div>
          )}


          {this.state.showAugmentedQuestion && (
            <div style={{ marginTop: 8, backgroundColor: "white", border: "2px solid gray", color: "black" }} className="dismissable-info">
              <span style={{ marginTop: 7, fontSize: 16 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ width: "10%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <span style={{ fontWeight: "bold" }}>Augmented Question: </span>
                  </div>
                  <div style={{ width: "85%" }}>
                    <input
                      value={this.state.agumentedQuestion}
                      style={{ width: '100%', height: 50 }}
                      onChange={this.handleAugmentInputChange}
                    />
                  </div>
                  <div style={{ width: "5%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div onClick={this.augmentSearchHandler} style={{ marginLeft: 10, width: 100, height: 50, backgroundColor: "#533278", display: "flex", justifyContent: "center", alignItems: "center", color: "white", cursor: "pointer" }}>
                      Search
                    </div>
                  </div>
                </div>

                {this.state.userProgramName && (
                  <div>
                    <span style={{ fontWeight: "bold" }}>Program Group: </span>{this.state.userProgramName}
                  </div>
                )}
                {this.state.userPlanName && (
                  <div>
                    <span style={{ fontWeight: "bold" }}>Program Name: </span>{this.state.userPlanName}
                  </div>
                )}
              </span>
            </div>
          )}


          {!this.state.isDismissed && this.state.answer ? (
            <>
              <div style={{ marginTop: 8, backgroundColor: "white", border: "2px solid gray", color: "black" }} className="dismissable-info">
                <div style={{ fontWeight: "bold", textDecoration: "underline", fontSize: 25 }}>Answer</div>

                <div className="dismiss">
                  <img
                    className="close"
                    src={close.default}
                    alt="X"
                    onClick={this.onDismiss}
                  />
                </div>
                <div style={{ marginTop: 7, fontSize: 20 }}>

                  <p id="answer"></p>

                  <div id="question"></div>

                  {this.state.showFeedbackMessage && (
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <div style={{ margin: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        Your feedback is captured. Click <span onClick={() => this.search()} style={{ fontWeight: "bold", color: "white", marginLeft: 5, marginRight: 5, cursor: "pointer", backgroundColor: "#00baba", width: 60, borderRadius: 20, display: "flex", alignItems: "center", justifyContent: "center", fontStyle: "italic" }}>here</span> to provide revised feedback.
                      </div>
                    </div>
                  )}

                  {this.state.showFeedBackButton && (
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <div style={{ margin: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        Feedback
                      </div>
                      <div style={{ margin: 5 }}>
                        <FontAwesomeIcon
                          onClick={this.feedbackThumpsUpHandler}
                          color={this.state.thumpsup_selected_index == 1 ? "#39FF14" : "#543379"}
                          style={{ cursor: "pointer" }}
                          icon={faThumbsUp}
                          size="2x"
                        />
                      </div>

                      <div style={{ margin: 5 }}>
                        <FontAwesomeIcon
                          onClick={this.feedbackThumpsDownHandler}
                          color={this.state.thumpsdown_selected_index == 2 ? "#FF0000" : "#543379"}
                          style={{ cursor: "pointer" }}
                          icon={faThumbsDown}
                          size="2x"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

            </>

          ) : null
          }

          {
            this.state.answerLoader && (
              <div className="loader-div">
                <div>
                  <div className="loader" />
                  <div className="loader-msg">Generating Results..</div>
                </div>
              </div>
            )
          }
          {
            this.state.noAnswerToDisplay && (
              <div className="no-result">No Answer To Display</div>
            )
          }


          {
            this.state.answerList.length > 0 && (
              <div style={{ cursor: "pointer" }} className="answers-container">
                <Row>
                  {this.state.answerList.map((value, index) => {
                    return this.renderAnswer(value, index);
                  })}
                </Row>
              </div>
            )
          }


          <div className="footer">

            <div style={{ display: "flex", width: "30%", flexDirection: "column", marginLeft: 20, justifyContent: "center" }}>
              <div
                onClick={() => this.showComparitorTool()}
                className="comparatortoolfooter"
              >
                <FontAwesomeIcon
                  style={{ cursor: "pointer", paddingRight: 10 }}
                  icon={faScrewdriverWrench}
                  size="1x"
                  className="fa-solid"
                />
                Comparator
              </div>
            </div>

            <div style={{ display: "flex", width: "50%", flexDirection: "column", marginLeft: 20, justifyContent: "center" }}>
            </div>
            <div style={{ display: "flex", width: "30%", flexDirection: "column", marginLeft: 20, justifyContent: "center" }}>
            </div>
          </div>

        </div >
      </div >
    );
  }

  renderAnswer = (value, index) => {

    try {

      return (
        <Col key={index} xs={12} xl={4}>
          <div style={{ backgroundColor: "#eee", marginTop: 10, borderRadius: 10 }}>
            <div style={{ padding: 10 }}>
              {/* <div style={{ fontWeight: "bold", padding: 15 }}>{value.answer}</div> */}

              <div style={{ marginTop: 10, padding: 15 }}>

                {this.state.show_full_answer && this.state.show_full_answer_index == index ? (
                  <div>{value.context}<span className="see-more" onClick={() => this.seeLessHandler(index)}>  See Less</span></div>
                ) : (
                  <>
                    {value.context && value.context.length > 100 ? (
                      <div>
                        <div>{value.context.slice(0, 100)} <span className="see-more" onClick={() => this.seeMoreHandler(index)}>  See More</span></div>
                      </div>
                    ) : (
                      <div>{value.context}</div>
                    )}
                  </>
                )}


              </div>
              <div style={{ height: 100 }}>

                <Row style={{ height: "100%", padding: 15 }}>
                  <Col xs={6} xl={5}>
                    <div style={{ display: "flex", height: "100%", alignItems: "center", justifyContent: "flex-start" }}>
                      <div
                        onClick={() => this.onClickOpenFile(value)}
                        style={{
                          cursor: "pointer"
                        }}
                      >
                        <div style={{ border: "1px solid black", backgroundColor: "#533278", color: "white" }}>Click Here For Document</div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} xl={7}>
                    <div style={{ display: "flex", height: "100%", alignItems: "center", justifyContent: "flex-end", flexDirection: "row" }}>

                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col >

      );
    } catch (err) { }
  };
}

export default Main;
