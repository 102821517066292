//dev config
// var config = {
//   jsonUrl: "https://dev-aikb-dropdown-lists.s3.us-east-2.amazonaws.com/dropdown.json",
//   jsonUrlComparitator: "https://dev-aikb-dropdown-lists.s3.us-east-2.amazonaws.com/dropdown_comparator.json",
//   hostURL: "https://doc.inf-labs.com/",
//   hostURLChatBot: "https://dev.apikb.carynhealth.com/",
//   hostDocGen: "https://dev.apikb.carynhealth.com/",
//   pdfUrlRedirect: "https://dev.aikb.carynhealth.com"
// };

//prod config
var config = {
  jsonUrl: "https://aikb-dropdown-list.s3.us-east-2.amazonaws.com/dropdown.json",
  jsonUrlComparitator: "https://dev-aikb-dropdown-lists.s3.us-east-2.amazonaws.com/dropdown_comparator.json",
  hostURL: "https://doc.inf-labs.com/",
  hostURLChatBot: "https://apikb.carynhealth.com/",
  hostDocGen: "https://apikb.carynhealth.com/",
  pdfUrlRedirect: "https://aikb.carynhealth.com"
};

export default config;
