import { AES, enc } from "crypto-js";
import crypto from 'crypto';


function decryptValue(encryptedData) {
    if (encryptedData) {
        const decodedData = decodeURIComponent(encryptedData);
        const key = Buffer.from('000102030405060708090a0b0c0d0e0f');
        const iv = Buffer.from('4t7w9z$C&F)J@NcR');

        const decipher = crypto.createDecipheriv('aes-256-cbc', key, iv);

        let decrypted = decipher.update(decodedData, 'base64', 'utf-8');
        decrypted += decipher.final('utf-8');

        const finalData = JSON.parse(decrypted);

        return finalData;
    } else {
        return {}
    }
}

export default decryptValue;