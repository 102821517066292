import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function Header({ subscriberName }) {
  const logo = require("../src/assets/images/ask_caryn_logo.png");
  // const close = require("../src/assets/images/close_white.svg");
  return (
    <div className="header-container">
      <div className="logo">
        <img src={logo.default} alt="Ask Caryn" />
      </div>

      {subscriberName && (
        <div className="username ">
          <div style={{ display: "flex", hustifyContent: "center", alignItems: "center" }}>
            <FontAwesomeIcon
              style={{ cursor: "pointer", paddingRight: 10, fontSize: 15 }}
              icon={faCircle}
              color="#4CAF50"
              className="fa-solid bright"
            />
            <span>{subscriberName}</span></div>
        </div>
      )}

      <div className="caption">Your AI-Powered Assistant</div>

    </div>
  );
}

export default Header;
