import React, { Component } from "react";

import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";

import decryptValue from "./actions/decrypt";

export class App extends Component {
  constructor() {
    super();
    this.state = {
      isNav: true,
      primarySubscriberName: ""
    };
    this.mainRef = React.createRef();
  }

  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const isNav = params.get("isnav");
    if (isNav === "false") {
      this.setState({ isNav: false });
    }
    const encryptedData = params.get("result");
    let data = {};

    const decryptionValues = decryptValue(encryptedData);

    
    this.setState({
      primarySubscriberName: decryptionValues?.PrimarySubscriberName
    })
  }

  render() {
    return (
      <div>
        {this.state.isNav ? <Header subscriberName={this.state.primarySubscriberName} /> : null}
        <Main ref={this.mainRef} />
        <Footer
          handleAccept={() => {
            this.mainRef.current.onEnableTextSearch();
          }}
        />
      </div>
    );
  }
}

export default App;
